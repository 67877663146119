import '../../../_metronic/_assets/plugins/flaticon/flaticon.css';
import '../../../_metronic/_assets/plugins/flaticon2/flaticon.css';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';

import { useFbPixel, FbPixelEvents } from '../../../hooks/FbPixel';
import { useQuery } from 'react-query';
import { plansApi } from '../../../api/plans';

import { WhatsAppWidget } from '../../../components/widgets/WhatsAppWidget';
import { apiFactory } from '../../../api/ApiFactory';
import { ContentContext } from '../components/Context/ContentContext';
import { PlanContext } from '../components/Context/PlanContext';
import { Sidebar } from '../components/Sidebar';
import { Form } from '../components/Form';
import { useGTM } from '../../../hooks/GTM';
import { useGoogleAnalytics } from '../../../hooks/Analytics';

import axios from 'axios';
import { IPlan } from '../../../api/plans/interfaces/planInterface';
import { BillingType } from '../components/constants/billingType';
import { SubscriptionContext } from '../components/Context/SubscriptionContext';
import { ISubscription } from '../../../api/subscriptions/types/subscription.type';
import { checkoutsApi } from '../../../api/checkouts';
import { Row } from 'react-bootstrap';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { useNavigate } from 'react-router-dom';

interface IParams extends Record<string, string> {
	reference: string;
	platform: string;
}

const { list } = plansApi;
const { findByPlan } = checkoutsApi;

export const RecurringCheckout = () => {
	const navigate = useNavigate();

	const [plans, setPlans] = useState<IPlan[]>();
	const [currentPlan, setCurrentPlan] = useState<IPlan>();
	const [subscription, setSubscription] = useState<ISubscription | null>(
		null
	);

	const { reference, platform } = useParams<IParams>() as IParams;
	const { track } = useFbPixel();
	const { pageView, selectItem } = useGoogleAnalytics();
	useGTM();

	const [currentPrice, setCurrentPrice] = useState<number>(0);

	const { data: plansData, isLoading } = useQuery(
		[list.key, { reference }],
		list.fetch,
		{
			refetchOnWindowFocus: false
		}
	);

	const { finance } = useContext(ApplicationContext);

	useEffect(() => {
		if (plansData && plansData.data.length === 0) {
			navigate('/unavailable');
		}

		if (!finance) return;
		if (finance.isCurseducaPayConfigured === true) return;

		if (!currentPlan) return;
		if (currentPlan.price === 0) return;

		navigate('/unavailable');
	}, [finance, currentPlan]);

	useEffect(() => {
		setPlans(plansData?.data);
	}, [plansData]);

	useEffect(() => {
		pageView();
	}, [pageView]);

	useEffect(() => {
		if (track) track(FbPixelEvents.InitiateCheckout);
	}, [track]);

	useEffect(() => {
		const currentPlan = plans?.find((plan) => plan.reference === reference);

		setCurrentPlan(currentPlan ? currentPlan : plans?.[0]);
	}, [plans]);

	useEffect(() => {
		setCurrentPrice(currentPlan?.price || 0);

		if (!currentPlan) return;
		selectItem({
			item: {
				id: currentPlan?.id,
				name: currentPlan?.name,
				price: currentPlan?.price
			}
		});
	}, [currentPlan]);

	useEffect(() => {
		apiFactory.isDefaultApiKeyDefined().then((response) => {
			if (response) return;

			axios
				.get<{ key: string }>(
					`${process.env.REACT_APP_CURSEDUCA_APPLICATION_DOMAIN}/platforms/slug/${platform}`
				)
				.then((response) => {
					apiFactory.enableCustom(response.data.key);
				});
		});

		return () => apiFactory.enableDefault();
	}, []);

	const { data: checkout } = useQuery(
		[findByPlan.key, { id: currentPlan?.id }],
		findByPlan.fetch,
		{
			refetchOnWindowFocus: false
		}
	);

	return (
		<ContentContext.Provider
			value={{
				currentPrice,
				quantity: 1,
				setQuantity: () => false,
				setCurrentPrice,
				isLoading,
				billingType: BillingType.CreditCard,
				setBillingType: () => false
			}}
		>
			<PlanContext.Provider
				value={{ plan: currentPlan, plans, setPlan: setCurrentPlan }}
			>
				<SubscriptionContext.Provider
					value={{ subscription, setSubscription }}
				>
					<div
						style={{
							backgroundImage: `url(${checkout?.backgroundImage})`,
							backgroundSize: 'cover'
						}}
						className="d-flex flex-column align-items-center"
					>
						<div className="content">
							<div className="container">
								{checkout?.headerImage && (
									<Row>
										<img
											className="w-100 h-100"
											src={checkout.headerImage}
										/>
									</Row>
								)}
								<div className="checkout-grid">
									<Card className="rounded-xl p-md-10 form-grid-item">
										<Form />
									</Card>
									<Sidebar />
								</div>
							</div>
						</div>
					</div>
					<WhatsAppWidget />
				</SubscriptionContext.Provider>
			</PlanContext.Provider>
		</ContentContext.Provider>
	);
};
